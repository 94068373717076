import { CONSTANTS } from "../helpers/variables";
import axiosService from "./axios.service";

class ReceiptService {
    async getReceipts(keywords, currentPage, perPage, sortBy, sortDesc) {
        let keyword = keywords ? keywords : ""; 
        let receipts = await axiosService.get(CONSTANTS.APIURL + `receipts-list?keywords=${keyword}&page=${currentPage}&per_page=${perPage}&column=${sortBy}&orderby=${sortDesc}`);
        return receipts;
    }
    async getReceiptsById(id) {
        let receipts = await axiosService.get(CONSTANTS.APIURL + `receipt?id=${id}` );
        return receipts;
    }
    async addReceipts(...receipts) {
        return await axiosService.post(CONSTANTS.APIURL + 'receipts', ...receipts);
    }
    async updateReceipts(...receipts) {
        return await axiosService.put(CONSTANTS.APIURL +  'receipts', ...receipts);
    }
    async deleteReceipts(id) {
        return await axiosService.detete(CONSTANTS.APIURL +`receipts?id=${id}`);
    }
    async getReceipt() {
        return await axiosService.get(CONSTANTS.APIURL +`receipts`);
    }
}

export default new ReceiptService();